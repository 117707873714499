import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import {FirmsAPIService} from './services/firms.service';
import {RatingComponent} from './components/rating/rating.component';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { AvatarComponent } from './components/avatar/avatar.component';

@NgModule({
    declarations: [
        NavbarComponent,
        FooterComponent,
        RatingComponent,
        AvatarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        RatingComponent,
        AvatarComponent
    ],
    providers: [
        FirmsAPIService
    ]
})
export class SharedModule {
}
