<div class="sign-in-component">
    <ul class="flex-container">
        <li class="modal-heading">
            <h1>Sign In</h1>
        </li>
        <li>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <p>
                    <mat-form-field>
                        <input type="text" autocomplete="new-text" matInput placeholder="Email" formControlName="username">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field>
                        <input type="password" autocomplete="new-password" matInput placeholder="Password" formControlName="password">
                    </mat-form-field>
                </p>

                <p *ngIf="error" class="error">
                    {{ error }}
                </p>
            </form>
        </li>
        <li class="forgot-pass">
            <a href="">Forgot Password?</a>
        </li>
        <li class="sign-in-button">
            <button class="gradient-button gradient-button-4" type="submit" mat-button cdkFocusInitial>SIGN IN</button>
        </li>
        <li class="or-sign-up">
            or Sign in with:
        </li>
        <li class="social-icons">
            <button mat-fab (click)="facebookLogin()"><img src="./assets/images/social/facebook.svg" alt="fb-signin"></button>
            <button mat-fab (click)="googleLogin()"><img src="./assets/images/social/google.svg" alt="google-signin"></button>
            <button mat-fab (click)="amazonLogin()"><img src="./assets/images/social/amazon.svg" alt="amazon-signin"></button>
        </li>
        <li class="horizontal-line">
            <hr>
        </li>
        <li class="sign-up">
            <p>Not a member? <a href="">Sign Up</a></p>
        </li>
    </ul>
</div>
