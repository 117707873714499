<!--<h1>Firm Id: {{firmId}}</h1>-->
<mat-spinner *ngIf="loading"></mat-spinner>
<ul class="firm" *ngIf="!loading && firm">
    <li class="firm-header">
        <ul class="firm-header-container">
            <li class="firm-logo">
<!--                <ngx-avatar name="{{getNameWithoutQ(firm.name)}}" [round]="false" [cornerRadius]="5" [size]="120"></ngx-avatar>-->
                <app-avatar [name]="getNameWithoutQ(firm.name)" [size]="'120'" [fontSize]="'48'"></app-avatar>
            </li>
            <li class="firm-header-details">
                <ul class="firm-header-details-container">
                    <li class="center-align"><h1>{{firm.name}}</h1></li>
                    <li class="firm-rating">
                        <app-rating [rating]="rating" class="ratings" [starCount]="starCount" [color]="starColor"
                                    (ratingUpdated)="onRatingChanged($event)"></app-rating>
                    </li>
                    <li class="firm-social center-align">
                        <img src="./assets/images/social/facebook.svg" alt="fb-signin">
                        <img src="./assets/images/social/google.svg" alt="google-signin">
                        <img src="./assets/images/social/twitter.svg" alt="amazon-signin">
                        <img src="./assets/images/social/pinterest.svg" alt="amazon-signin">
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li class="firm-details">
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Contact Info">
                <div class="mat-tab-body-content">
                    <div>
                        <p><b>Point of contact: </b><i>{{firm.contact | titlecase}}</i></p>
                    </div>
                    <div>
                        <br>
                    </div>
                    <div>
                        <p><b>Phone number: </b><i>{{firm.phone}}</i></p>
                    </div>
                    <div>
                        <br>
                    </div>
                    <div>
                        <p><b>Address: </b><i>{{firm['street'] | titlecase}}, {{firm['city'] | titlecase}}
                            , {{firm['state']}} {{firm['zip'] | titlecase}}</i></p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Services">
                <ol class="mat-tab-body-content">
                    <ng-container *ngFor="let service of getFirmServices(firm.services); let i=index">
                        <li>{{service}}</li>
                    </ng-container>
                </ol>
            </mat-tab>
            <mat-tab label="Reviews"></mat-tab>
        </mat-tab-group>
    </li>
</ul>
