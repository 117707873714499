<div class="firm-results">
<!--    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>-->
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div *ngIf="!isNotFound && !loading"><i>Showing results for: </i><b>{{zipCode}}</b></div>
    <ul *ngIf="!isNotFound && !loading" class="firm-results-container">
        <li class="column-item-firms">
            <div *ngFor="let firm of firms; let i = index">
                <app-firm-summary-card [counter]="i+1" [firm]="firm"></app-firm-summary-card>
            </div>
        </li>
        <li class="column-item-map">
            <google-map   height="80vh"
                          width="90%"
                          [zoom]="13.5"
                          [options]="options"
                          [center]="center">
                <map-marker
                        *ngFor="let marker of markers"
                        [position]="marker.position"
                        [label]="marker.label"
                        [title]="marker.title"
                        [options]="marker.options">
                </map-marker>
            </google-map>
        </li>
    </ul>
    <div class="not-found" *ngIf="isNotFound">
        <img class="no-results-img" src="./assets/images/not_found.svg" alt="404">
        <br>
        <h3><b>No results</b> were found for <b>{{zipCode}}</b>, please try another ZIP code.</h3>
    </div>
</div>
