import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogData, SignInComponent} from '../sign-in/sign-in.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  @Input() error: string | null | undefined;

  @Output() submitEM = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    repeatPassword: new FormControl(''),
  });

  validatingForm: FormGroup = new FormGroup({
    modalFormElegantEmail: new FormControl('', Validators.email),
    modalFormElegantPassword: new FormControl('', Validators.required)
  });

  submit(): void {
    if (this.form.valid) {
      this.submitEM.emit(this.form.value);
    }
  }

  // tslint:disable-next-line:typedef
  get modalFormElegantEmail() {
    return this.validatingForm.get('modalFormElegantEmail');
  }

  // tslint:disable-next-line:typedef
  get modalFormElegantPassword() {
    return this.validatingForm.get('modalFormElegantPassword');
  }

  facebookLogin(): void {
    console.log('Login to facebook');
  }

  googleLogin(): void {
    console.log('Login to google');
  }

  appleLogin(): void {
    console.log('Login to apple');
  }

  openSignInDialog(): void {
    // this.dialog.open(SignInComponent, {panelClass: 'custom-container'});
  }

}
