import { Injectable } from '@angular/core';
import Auth, {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub} from 'aws-amplify';
import {Observable, Subject} from 'rxjs';
import {CognitoUser} from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {
    Hub.listen('auth', ( data ) => {
      const { channel, payload } = data;
      if (channel === 'auth') {
        this.authStateSubject.next(payload.event);
      }
    });
  }
  public static SIGN_IN = 'signIn';
  public static SIGN_OUT = 'signOut';
  public loggedIn: boolean | undefined;
  user: any;
  private authStateSubject: Subject<CognitoUser|any> = new Subject<CognitoUser|any>();
  authState: Observable<CognitoUser|any> = this.authStateSubject.asObservable();

  signUp(user: { password: any; name: any; email: any }): Promise<any> {
    return Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
        name: user.name,
      }
    });
  }

  signIn(username: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
          .then((user: CognitoUser | any) => {
            this.loggedIn = true;
            this.user = user;
            resolve(user);
          }).catch((error: any) => reject(error));
    });
  }

  signOut(): Promise<any> {
    return Auth.signOut()
        .then(() => this.loggedIn = false);
  }

  googleSignIn(): Promise<any> {
    return this.socialSignIn(CognitoHostedUIIdentityProvider.Google);
  }

  facebookSignIn(): Promise<any> {
    return this.socialSignIn(CognitoHostedUIIdentityProvider.Facebook);
  }

  amazonSignIn(): Promise<any> {
    return this.socialSignIn(CognitoHostedUIIdentityProvider.Amazon);
  }

  private socialSignIn(provider: any): any {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({provider})
          .then(result => {
            console.log(result);
            this.loggedIn = result.authenticated;
            resolve(result);
          }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  }

  getUser(): any {
    return this.user;
  }
}
