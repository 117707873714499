import {Component, Inject, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {environment} from '../../../environments/environment';

export interface DialogData {
    animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
    @Input() error: string | null | undefined;

    @Output() submitEM = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
                public dialogRef: MatDialogRef<SignInComponent>,
                private auth: AuthService) {
    }

    form: FormGroup = new FormGroup({
        username: new FormControl(''),
        password: new FormControl(''),
    });

    validatingForm: FormGroup = new FormGroup({
        modalFormElegantEmail: new FormControl('', Validators.email),
        modalFormElegantPassword: new FormControl('', Validators.required)
    });

    submit(): void {
        if (this.form.valid) {
            this.submitEM.emit(this.form.value);
        }
    }

    // tslint:disable-next-line:typedef
    get modalFormElegantEmail() {
        return this.validatingForm.get('modalFormElegantEmail');
    }

    // tslint:disable-next-line:typedef
    get modalFormElegantPassword() {
        return this.validatingForm.get('modalFormElegantPassword');
    }

    facebookLogin(): void {
        this.auth.facebookSignIn()
            .then(user => {
                console.log(user);
            })
            .catch((error: any) => {
                console.log(error);
            });
    }

    googleLogin(): void {
        this.auth.googleSignIn()
            .then(user => {
                console.log(user);
            })
            .catch((error: any) => {
                console.log(error);
            });
    }

    amazonLogin(): void {
        this.auth.amazonSignIn()
            .then(user => {
                console.log(user);
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
}
