<div class="footer">
    <div class="container-flex">
        <div class="footer-column-large"><img src="./assets/images/logo_alt.svg" width="130" alt="logo" class="footer-logo">
            <p class="footer-text">Find tax help.</p>
            <a href="#" class="footer-link-wrap w-inline-block"><img src="https://uploads-ssl.webflow.com/5d01778cda7c6cc8a63e0b64/5d1324fd4b05c818c6fbecc1_mail.svg" width="19" alt="" class="footer-icon">
                <p class="footer-text-02">findtaxcom@gmail.com</p>
            </a>
            <a href="#" class="footer-link-wrap w-inline-block"><img src="https://uploads-ssl.webflow.com/5d01778cda7c6cc8a63e0b64/5d1324fd4b05c84f5afbecc0_communication%20(1).svg" width="19" alt="" class="footer-icon">
                <p class="footer-text-02">Livechat</p>
            </a>
        </div>
        <div class="footer-column">
            <div class="footer-title">About Us</div>
            <a href="#" class="footer-link">Company</a>
            <a href="#" class="footer-link">Leadership</a>
            <a href="#" class="footer-link">Diversity</a>
            <a href="#" class="footer-link">FAQ</a>
            <a href="#" class="footer-link">News</a>
        </div>
        <div class="footer-column">
            <div class="footer-title">Services</div>
            <a href="#" class="footer-link">Interface Design</a>
            <a href="#" class="footer-link">Development</a>
            <a href="#" class="footer-link">Engineering</a>
            <a href="#" class="footer-link">UX Design</a>
            <a href="#" class="footer-link">Prototyping</a>
        </div>
        <div class="footer-column">
            <div class="footer-title">Press</div>
            <a href="#" class="footer-link">FAQ</a>
            <a href="#" class="footer-link">About Us</a>
            <a href="#" class="footer-link">News</a>
        </div>
    </div>
<!--    <div class="legal-wrap">-->
<!--&lt;!&ndash;        <div class="legal-text">© Copyright 2021 findtax.com </div>&ndash;&gt;-->
<!--        <div class="social-link-wrap">-->
<!--            <a href="https://twitter.com/madebyflowcraft" target="_blank" class="social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/5d6f91252fd62f03bd2330e7/5d6f91252fd62f3d9d233139_twitter%20(4).svg" alt=""></a>-->
<!--            <a href="https://www.instagram.com/madebyflowcraft/" target="_blank" class="social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/5d6f91252fd62f03bd2330e7/5d6f91252fd62f9df7233138_instagram%20(6).svg" alt=""></a>-->
<!--            <a href="https://gumroad.com/flowcraft" target="_blank" class="social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/5d6f91252fd62f03bd2330e7/5d81e9e6e7908e56bf587612_Gumroad.svg" alt=""></a>-->
<!--        </div>-->
<!--    </div>-->
</div>
