import {Component, OnInit, ViewChild} from '@angular/core';
import {FirmsAPIService} from '../../shared/services/firms.service';
import {ActivatedRoute} from '@angular/router';
import {MapsService} from '../../shared/services/maps.service';
import {toTitleCase} from 'codelyzer/util/utils';

@Component({
    selector: 'app-firm-results',
    templateUrl: './firm-results.component.html',
    styleUrls: ['./firm-results.component.scss']
})
export class FirmResultsComponent implements OnInit {
    firms: any;
    zipCode = '';
    loading = false;
    markers = Array();
    center = {lat: 47.5476069, lng: -122.3514653};
    isNotFound = false;

    options: google.maps.MapOptions = {
        styles: [
            {
                featureType: 'all',
                stylers: [
                    {
                        saturation: 0
                    },
                    {
                        hue: '#e7ecf0'
                    }
                ]
            },
            {
                featureType: 'road',
                stylers: [
                    {
                        saturation: -70
                    }
                ]
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        saturation: -60
                    }
                ]
            }
        ],
        // styles: [
        //     {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
        //     {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
        //     {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
        //     // {
        //     //     featureType: 'administrative.locality',
        //     //     elementType: 'labels.text.fill',
        //     //     stylers: [{color: '#d59563'}],
        //     // },
        //     // {
        //     //     featureType: 'poi',
        //     //     elementType: 'labels.text.fill',
        //     //     stylers: [{color: '#d59563'}],
        //     // },
        //     // {
        //     //     featureType: 'poi.park',
        //     //     elementType: 'geometry',
        //     //     stylers: [{color: '#263c3f'}],
        //     // },
        //     // {
        //     //     featureType: 'poi.park',
        //     //     elementType: 'labels.text.fill',
        //     //     stylers: [{color: '#6b9a76'}],
        //     // },
        //     {
        //         featureType: 'road',
        //         elementType: 'geometry',
        //         stylers: [{color: '#38414e'}],
        //     },
        //     {
        //         featureType: 'road',
        //         elementType: 'geometry.stroke',
        //         stylers: [{color: '#212a37'}],
        //     },
        //     {
        //         featureType: 'road',
        //         elementType: 'labels.text.fill',
        //         stylers: [{color: '#9ca5b3'}],
        //     },
        //     {
        //         featureType: 'road.highway',
        //         elementType: 'geometry',
        //         stylers: [{color: '#746855'}],
        //     },
        //     {
        //         featureType: 'road.highway',
        //         elementType: 'geometry.stroke',
        //         stylers: [{color: '#1f2835'}],
        //     },
        //     {
        //         featureType: 'road.highway',
        //         elementType: 'labels.text.fill',
        //         stylers: [{color: '#f3d19c'}],
        //     },
        //     // {
        //     //     featureType: 'transit',
        //     //     elementType: 'geometry',
        //     //     stylers: [{color: '#2f3948'}],
        //     // },
        //     // {
        //     //     featureType: 'transit.station',
        //     //     elementType: 'labels.text.fill',
        //     //     stylers: [{color: '#d59563'}],
        //     // },
        //     {
        //         featureType: 'water',
        //         elementType: 'geometry',
        //         stylers: [{color: '#17263c'}],
        //     },
        //     {
        //         featureType: 'water',
        //         elementType: 'labels.text.fill',
        //         stylers: [{color: '#515c6d'}],
        //     },
        //     {
        //         featureType: 'water',
        //         elementType: 'labels.text.stroke',
        //         stylers: [{color: '#17263c'}],
        //     },
        // ]
    };

    constructor(private firmsAPIService: FirmsAPIService,
                private mapsService: MapsService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const keywords = this.route.snapshot.paramMap.get('keywords');
        if (keywords) {
            this.zipCode = keywords;
            this.loading = true;
            this.mapsService.getLatLong(this.zipCode).subscribe(
                (data: any) => {
                    if (data && data.results && data.results.length) {
                        this.center = {
                            lat: data.results[0].geometry.location.lat,
                            lng: data.results[0].geometry.location.lng
                        };
                    }
                }
            );
            this.firmsAPIService.loadFirmsByZip(keywords)
                .then(res => {
                    if (res) {
                        if (res.items && res.items.length) {
                            this.firms = res.items;
                            this.isNotFound = false;
                            // Add markers
                            this.firms.forEach((firm: any) => {
                                this.addMarker(firm.name, `${firm.street}, ${firm.city}, ${firm.state} ${firm.zip}`);
                            });
                        } else {
                            this.isNotFound = true;
                        }
                        this.loading = false;
                    }
                }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        }
    }

    addMarker(name: string, address: string): void {
        if (this.markers) {
            this.mapsService.getLatLong(address).subscribe(
                (data: any) => {
                    if (data && data.results && data.results.length) {
                        this.markers.push({
                            position: {
                                lat: data.results[0].geometry.location.lat,
                                lng: data.results[0].geometry.location.lng
                            },
                            title: toTitleCase(name),
                            info: toTitleCase(address)
                        });
                    }
                }
            );
        }
    }

}
