import gql from 'graphql-tag';

export const GetQueryFirmsByZipIndex = gql`
query QueryFirmsByZipIndex($zip: String!, $first: Int, $after: String) {
    queryFirmsByZipIndex(zip: $zip, first: $first, after: $after) {
    __typename
    items {
        __typename
        id
        zip
        name
        city
        state
        street
        phone
        contact
        updated
        services
    }
    nextToken
    }
}`;

export const GetQueryFirmById = gql`
    query QueryFirmById($id: String!) {
        getFirms(id: $id) {
            city
            contact
            id
            name
            phone
            services
            state
            street
            updated
            zip
        }
    }`;

const statement = `query GetFirms($id: String!) {
        getFirms(id: $id) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`;

export const GET_PROFILES = gql`
    query healthcareWorkers($pageNo: Int = 0, $pageSize: Int = 6) {
        healthcareWorkers(pageNo: $pageNo, pageSize: $pageSize) {
            healthcareWorkers {
                firstname
            }
        }
    }
`;
