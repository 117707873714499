import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingComponent} from './core/landing/landing.component';
import {SignupComponent} from './core/signup/signup.component';
import {FirmResultsComponent} from './search/firm-results/firm-results.component';
import {FirmComponent} from './search/firm/firm.component';
import {AuthGuardService} from './auth/auth-guard.service';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'search/firmresults/:keywords', component: FirmResultsComponent },
  { path: 'search/firm/:fid', component: FirmComponent},
  // { path: 'search/firm/:fid', component: FirmComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
