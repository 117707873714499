<!-- Section: Header -->
<header class="header">
    <div class="container">
        <section class="wrapper">
            <div>
                <a routerLink="/" class="logo-link-2 w-inline-block"><img src="./assets/images/FindTax.png" height="23px" width="120px" alt=""></a>
            </div>
            <a type="button" class="opened-menu">
                <img src="./assets/images/menu.png" class="closed-icon" alt="closed">
            </a>
<!--            <button type="button" class="opened-menu">-->
<!--                <mat-icon [inline]="true">menu</mat-icon>-->
<!--            </button>-->
            <div class="overlay"></div>
            <nav class="navbar">
                <button type="button" class="closed-menu">
                    <mat-icon>close</mat-icon>
                </button>
                <ul class="menu">
                    <li class="menu-item menu-item-has-children">
                        <a href="#" class="navbar-item" data-toggle="sub-menu">Tax Tools<i class="expand"></i></a>
                        <ul class="sub-menu">
                            <li class="menu-item"><a href="https://www.dinkytown.net/taxes.html" target="_blank">Calculators</a></li>
                            <li class="menu-item"><a href="https://answerconnect.cch.com/" target="_blank">Research - CCH</a></li>
                            <li class="menu-item"><a href="https://tax.thomsonreuters.com/en/checkpoint/edge" target="_blank">Research - [Checkpoint] Thomson Reuters</a></li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="#" class="navbar-item" data-toggle="sub-menu">Services<i class="expand"></i></a>
                        <ul class="sub-menu">
                            <li class="menu-item"><a href="#" target="_blank">Promote Your Firm</a></li>
                            <li class="menu-item"><a href="#" target="_blank">Build Your Website</a></li>
                            <li class="menu-item"><a href="#" target="_blank">SEO and Optimizing</a></li>
                        </ul>
                    </li>
<!--                    <li class="menu-item">-->
<!--                        <a class="signin-button" mat-stroked-button color="accent" (click)="openSignInDialog()"><mat-icon>lock_open </mat-icon> Sign-in</a>-->
<!--                    </li>-->
<!--                    <li class="menu-item">-->
<!--                        <a class="auth-button gradient-button gradient-button-4" mat-raised-button (click)="openSignUpDialog()">Sign-up <mat-icon>perm_identity </mat-icon></a>-->
<!--                    </li>-->
                </ul>
            </nav>
        </section>
    </div>
</header>
