import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FirmsAPIService} from '../../shared/services/firms.service';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss']
})
export class FirmComponent implements OnInit {
  rating = 0;
  starCount = 5;
  starColor = 'accent';
  loading = false;
  firm: any;
  firmId: string | null | undefined;

  constructor(private route: ActivatedRoute, private firmsService: FirmsAPIService) { }

  ngOnInit(): void {
    this.firmId = this.route.snapshot.paramMap.get('fid');
    if (this.firmId) {
      this.firmsService.getFirmById(this.firmId)
          .then(res => {
            if (res) {
              this.loading = false;
              this.firm = res;
            }
          }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    }
  }

  onRatingChanged(rating: any): void {
    this.rating = rating;
  }

  getFirmServices(services: string): string[] {
    return services.split(',');
  }

  getNameWithoutQ(firmName: string): string {
    return firmName.split(',')[0]?.split(' ').slice(0, 3).join(' ');
  }
}
