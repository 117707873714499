import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input('rating') rating = 4;
  @Input('starCount') starCount = 7;
  @Input('color') color = 'green';
  @Output() private ratingUpdated = new EventEmitter();

  ratingArr: number[] = [];

  constructor() {}

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating: number): any {
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number): any {
    if (this.rating >= index + 1) {
      return 'favorite';
    } else {
      return 'favorite_border';
    }
    // if (this.rating >= index + 1) {
    //   return 'star';
    // } else {
    //   return 'star_border';
    // }
  }

}
