import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SignInComponent} from '../../../auth/sign-in/sign-in.component';
import {SignUpComponent} from '../../../auth/sign-up/sign-up.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

    navbarMenu: any;
    menuOverlay: any;

    constructor(public dialog: MatDialog,
                private elementRef: ElementRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const openedMenu = document.querySelector('.opened-menu');
        const closedMenu = document.querySelector('.closed-menu');
        this.navbarMenu = document.querySelector('.navbar');
        this.menuOverlay = document.querySelector('.overlay');

        // Opened navbarMenu
        // Closed navbarMenu
        // Closed navbarMenu by Click Outside
        openedMenu?.addEventListener('click', this.toggleMenu);
        closedMenu?.addEventListener('click', this.toggleMenu);
        this.menuOverlay?.addEventListener('click', this.toggleMenu);
        if (window.innerWidth <= 992) {
            const mySubMenus = this.navbarMenu.querySelectorAll('.menu-item-has-children .sub-menu');
            mySubMenus.forEach((menu: { style: { display: string; }; }) => menu.style.display = 'none');
        }

        this.navbarMenu.addEventListener('click',
            (event: {
                target: { hasAttribute: (arg0: string) => any; parentElement: any; };
                preventDefault: () => void;
            }) => {
                if (event.target.hasAttribute('data-toggle') && window.innerWidth <= 992) {
                    // Prevent Default Anchor Click Behavior
                    event.preventDefault();
                    const menuItemHasChildren = event.target.parentElement;

                    // If menuItemHasChildren is Expanded, Collapse It
                    if (menuItemHasChildren.classList.contains('active')) {
                        this.collapseSubMenu();
                    } else {
                        // Collapse Existing Expanded menuItemHasChildren
                        if (this.navbarMenu.querySelector('.menu-item-has-children.active')) {
                            this.collapseSubMenu();
                        }
                        // Expand New menuItemHasChildren
                        menuItemHasChildren.classList.add('active');
                        const subMenu = menuItemHasChildren.querySelector('.sub-menu');
                        subMenu.style.display = 'block';
                        subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
                    }
                }
            });

        window.addEventListener('resize', () => {
            if (window.innerWidth > 992) {
                this.resizeScreen();
            }
        });
    }

    openSignInDialog(): void {
        this.toggleMenu();
        // this.dialog.open(SignInComponent);
        this.dialog.open(SignInComponent, {
            width: '40%'
        });
    }

    openSignUpDialog(): void {
        this.toggleMenu();
        this.dialog.open(SignUpComponent, {panelClass: 'custom-container'});
    }

    toggleMenu(): void {
        if (!this.navbarMenu) {
            this.navbarMenu = document.querySelector('.navbar');
        }
        if (!this.menuOverlay) {
            this.menuOverlay = document.querySelector('.overlay');
        }
        this.navbarMenu.classList.toggle('active');
        this.menuOverlay.classList.toggle('active');
        document.body.classList.toggle('scrolling');
    }

    collapseSubMenu(): void {
        this.navbarMenu.querySelector('.menu-item-has-children.active .sub-menu').removeAttribute('style');
        this.navbarMenu.querySelector('.menu-item-has-children.active').classList.remove('active');
        const mySubMenus = this.navbarMenu.querySelectorAll('.menu-item-has-children .sub-menu');
        mySubMenus.forEach((menu: { style: { display: string; }; }) => menu.style.display = 'none');
    }

    resizeScreen(): void {
        // If navbarMenu is Open, Close It
        if (this.navbarMenu.classList.contains('active')) {
            this.toggleMenu();
        }

        // If menuItemHasChildren is Expanded, Collapse It
        if (this.navbarMenu.querySelector('.menu-item-has-children.active')) {
            this.collapseSubMenu();
        }
    }
}
