import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input()
    public photoUrl = '';

    @Input()
    public name = '';

    @Input()
    public size = '';

    @Input()
    public fontSize = '';

    public showInitials = false;
    public initials = '';
    public circleColor = '';

    private colors = [
        '#ef4444',
        '#f97316',
        '#f59e0b',
        '#84cc16',
        '#22c55e',
        '#10b981',
        '#6366f1',
        '#06b6d4',
        '#a855f7',
        '#ec4899',
    ];

    ngOnInit() {

        if (!this.photoUrl) {
            this.showInitials = true;
            this.createInititals();

            const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
            this.circleColor = this.colors[randomIndex];
        }

    }

    private createInititals(): void {
        let initials = '';

        for (let i = 0; i < this.name.length; i++) {
            if (this.name.charAt(i) === ' ') {
                continue;
            }

            if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
                initials += this.name.charAt(i);

                if (initials.length == 2) {
                    break;
                }
            }
        }

        this.initials = initials;
    }

}
