import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  firmSearchForm = this.formBuilder.group({
    keywords: '',
  });
  constructor(private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  accountantSearch(): void {
    console.log('Im an accountant');
  }

  onSubmit(): void {
    this.router.navigate(['/search/firmresults', this.firmSearchForm.value.keywords]);
  }
}
