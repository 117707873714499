import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FirmResultsComponent} from './firm-results/firm-results.component';
import {FirmSummaryCardComponent} from './firm-summary-card/firm-summary-card.component';
import {MatCardModule} from '@angular/material/card';
import {SharedModule} from '../shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {GoogleMapsModule} from '@angular/google-maps';
import { FirmComponent } from './firm/firm.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [
        FirmResultsComponent,
        FirmSummaryCardComponent,
        FirmComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        SharedModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        GoogleMapsModule,
        MatTabsModule,
        MatButtonModule
    ]
})
export class SearchModule {
}
