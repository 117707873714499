import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'zen-observable-ts';


@Injectable({
    providedIn: 'root'
})
export class MapsService {

    constructor(private http: HttpClient) {}

    getLatLong(address: string): any {
        return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyA0Dx_boXQiwvdz8sJHoYeZNVTdoWONYkU&address=${address}`);
    }
}
