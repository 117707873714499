import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {SignInComponent} from '../../auth/sign-in/sign-in.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-firm-summary-card',
    templateUrl: './firm-summary-card.component.html',
    styleUrls: ['./firm-summary-card.component.scss']
})
export class FirmSummaryCardComponent implements OnInit {
    @Input()
    firm: any;

    @Input()
    counter: any;

    rating = 0;
    starCount = 5;
    starColor = 'accent';
    // starColorP = 'primary';
    // starColorW = 'warn';

    constructor(private router: Router,
                private dialog: MatDialog,
                private authService: AuthService) {
    }

    ngOnInit(): void {
    }

    onRatingChanged(rating: any): void {
        console.log(rating);
        this.rating = rating;
    }

    isValidFirm(firm: any): boolean {
        return firm && firm.name && firm.name.toLowerCase() !== 'n/a';
    }

    getFirmServices(services: string): string[] {
        return services.split(',');
    }

    getNameWithoutQ(firmName: string): string {
        return firmName.split(',')[0]?.split(' ').slice(0, 3).join(' ');
    }

    openFirm(firmId: string): void {
        // is logged-in?
        // const isLoggedIn = this.authService.loggedIn;
        const isLoggedIn = true;
        if (isLoggedIn) {
            this.router.navigate(['/search/firm', firmId]);
        } else {
            this.dialog.open(SignInComponent, {panelClass: 'custom-container'});
        }
    }
}
