<div class="firm-summary-card" *ngIf="isValidFirm(firm)">
        <mat-card (click)="openFirm(firm.id)" class="mat-elevation-z0 firm-main-card clickable-card">
            <div class="card-row">
<!--                <div class="counter">-->
<!--                    <h2>{{counter}}.</h2>-->
<!--                </div>-->
                <div class="firm-logo">
                    <app-avatar [name]="getNameWithoutQ(firm.name)" [size]="'48'" [fontSize]="'16'"></app-avatar>
                </div>
                <div class="center-align">
                    <mat-card-header>
                        <mat-card-title [style.font-weight]="'bolder'">{{ (firm.name.length > 30)? (firm.name | uppercase | slice:0:28)+'...': (firm.name | uppercase)}} </mat-card-title>
                        <mat-card-subtitle>{{firm['street'] | titlecase}}, {{firm['city'] | titlecase}}
                            , {{firm['state']}} {{firm['zip'] | titlecase}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <app-rating [rating]="rating" class="ratings" [starCount]="starCount" [color]="starColor"
                                    (ratingUpdated)="onRatingChanged($event)"></app-rating>
                    </mat-card-content>
                </div>
            </div>
        </mat-card>
</div>
