/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import {Injectable} from '@angular/core';
import API, {GRAPHQL_AUTH_MODE, graphqlOperation, GraphQLResult} from '@aws-amplify/api-graphql';
import {Observable} from 'zen-observable-ts';
import {AUTH_TYPE, AWSAppSyncClient} from 'aws-appsync';
import {GetQueryFirmById, GetQueryFirmsByZipIndex} from '../../../graphql/queries';
import {Firm} from '../models/firm.model';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateFirmsInput = {
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type Firms = {
  __typename: 'Firms';
  id?: string | null;
  zip?: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type UpdateFirmsInput = {
  id: string;
  zip?: string | null;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type DeleteFirmsInput = {
  id: string;
};

export type TableFirmsFilterInput = {
  id?: TableStringFilterInput | null;
  zip?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
  city?: TableStringFilterInput | null;
  state?: TableStringFilterInput | null;
  street?: TableStringFilterInput | null;
  phone?: TableStringFilterInput | null;
  contact?: TableStringFilterInput | null;
  updated?: TableStringFilterInput | null;
  services?: TableStringFilterInput | null;
};

export type TableStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type FirmsConnection = {
  __typename: 'FirmsConnection';
  items?: Array<Firms | null> | null;
  nextToken?: string | null;
};

export type CreateFirmsMutation = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type UpdateFirmsMutation = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type DeleteFirmsMutation = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type GetFirmsQuery = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type ListFirmsQuery = {
  __typename: 'FirmsConnection';
  items?: Array<{
    __typename: 'Firms';
    id?: string | null;
    zip: string;
    name?: string | null;
    city?: string | null;
    state?: string | null;
    street?: string | null;
    phone?: string | null;
    contact?: string | null;
    updated?: string | null;
    services?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type QueryFirmsByZipIndexQuery = {
  __typename: 'FirmsConnection';
  items?: Array<{
    __typename: 'Firms';
    id?: string | null;
    zip: string;
    name?: string | null;
    city?: string | null;
    state?: string | null;
    street?: string | null;
    phone?: string | null;
    contact?: string | null;
    updated?: string | null;
    services?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateFirmsSubscription = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type OnUpdateFirmsSubscription = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

export type OnDeleteFirmsSubscription = {
  __typename: 'Firms';
  id?: string | null;
  zip: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  street?: string | null;
  phone?: string | null;
  contact?: string | null;
  updated?: string | null;
  services?: string | null;
};

@Injectable({
  providedIn: 'root'
})
export class FirmsAPIService {

  client = new AWSAppSyncClient({
    url: 'https://npl5r7wp4famljdmttndyjmy74.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    auth: {type: AUTH_TYPE.API_KEY, apiKey: 'da2-dyjeftjmyfdvfbwrf2wd5t4j3q'}
  });

  async loadFirmsByZip(zip: string) {
    const query = GetQueryFirmsByZipIndex;
    const gqlFirmsAPIServiceArguments: any = {
      zip: zip
    };
    const response = (await this.client.query({query, variables: gqlFirmsAPIServiceArguments, fetchPolicy: 'network-only'})) as any;
    return response.data.queryFirmsByZipIndex;
  }

  async getFirmById(id: string) {
    const query = GetQueryFirmById;
    const gqlFirmsAPIServiceArguments: any = {
      id: id
    };
    const response = (await this.client.query({query, variables: gqlFirmsAPIServiceArguments, fetchPolicy: 'network-only'})) as any;
    return response.data.getFirms as Firm;
    // const gqlFirmsAPIServiceArguments: any = {
    //   id
    // };
    // const response = (await API.graphql(
    //     graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    // )) as any;
    // return <GetFirmsQuery>response.data.getFirms;
  }

  // async QueryFirmsByZipIndexClient(zip: string, first?: number, after?: string): Promise<QueryFirmsByZipIndexQuery> {
  //   const statement = `query QueryFirmsByZipIndex($zip: String!, $first: Int, $after: String) {
  //       queryFirmsByZipIndex(zip: $zip, first: $first, after: $after) {
  //         __typename
  //         items {
  //           __typename
  //           id
  //           zip
  //           name
  //           city
  //           state
  //           street
  //           phone
  //           contact
  //           updated
  //           services
  //         }
  //         nextToken
  //       }
  //     }`;
  //   const gqlFirmsAPIServiceArguments: any = {
  //     zip
  //   };
  //   if (first) {
  //     gqlFirmsAPIServiceArguments.first = first;
  //   }
  //   if (after) {
  //     gqlFirmsAPIServiceArguments.after = after;
  //   }
  //   console.log(graphqlOperation(statement, gqlFirmsAPIServiceArguments));
  //   const response = (await API.graphql(
  //       {
  //         query: statement,
  //         variables: gqlFirmsAPIServiceArguments,
  //         authMode: GRAPHQL_AUTH_MODE.API_KEY
  //       },
  //   )) as any;
  //   return <QueryFirmsByZipIndexQuery>response.data.queryFirmsByZipIndex;
  // }

  async CreateFirms(input: CreateFirmsInput): Promise<CreateFirmsMutation> {
    const statement = `mutation CreateFirms($input: CreateFirmsInput!) {
        createFirms(input: $input) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    )) as any;
    return <CreateFirmsMutation>response.data.createFirms;
  }
  async UpdateFirms(input: UpdateFirmsInput): Promise<UpdateFirmsMutation> {
    const statement = `mutation UpdateFirms($input: UpdateFirmsInput!) {
        updateFirms(input: $input) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    )) as any;
    return <UpdateFirmsMutation>response.data.updateFirms;
  }
  async DeleteFirms(input: DeleteFirmsInput): Promise<DeleteFirmsMutation> {
    const statement = `mutation DeleteFirms($input: DeleteFirmsInput!) {
        deleteFirms(input: $input) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    )) as any;
    return <DeleteFirmsMutation>response.data.deleteFirms;
  }
  async GetFirms(id: string): Promise<GetFirmsQuery> {
    const statement = `query GetFirms($id: String!) {
        getFirms(id: $id) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    )) as any;
    return <GetFirmsQuery>response.data.getFirms;
  }

  async ListFirms(
    filter?: TableFirmsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFirmsQuery> {
    const statement = `query ListFirms($filter: TableFirmsFilterInput, $limit: Int, $nextToken: String) {
        listFirms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            zip
            name
            city
            state
            street
            phone
            contact
            updated
            services
          }
          nextToken
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {};
    if (filter) {
      gqlFirmsAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlFirmsAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlFirmsAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlFirmsAPIServiceArguments)
    )) as any;
    return <ListFirmsQuery>response.data.listFirms;
  }

  async QueryFirmsByZipIndex(zip: string, first?: number, after?: string): Promise<QueryFirmsByZipIndexQuery> {
    const statement = `query QueryFirmsByZipIndex($zip: String!, $first: Int, $after: String) {
        queryFirmsByZipIndex(zip: $zip, first: $first, after: $after) {
          __typename
          items {
            __typename
            id
            zip
            name
            city
            state
            street
            phone
            contact
            updated
            services
          }
          nextToken
        }
      }`;
    const gqlFirmsAPIServiceArguments: any = {
      zip
    };
    if (first) {
      gqlFirmsAPIServiceArguments.first = first;
    }
    if (after) {
      gqlFirmsAPIServiceArguments.after = after;
    }
    console.log(graphqlOperation(statement, gqlFirmsAPIServiceArguments));
    const response = (await API.graphql(
        {
          query: statement,
          variables: gqlFirmsAPIServiceArguments,
          authMode: GRAPHQL_AUTH_MODE.API_KEY
        },
    )) as any;
    return <QueryFirmsByZipIndexQuery>response.data.queryFirmsByZipIndex;
  }


  OnCreateFirmsListener: Observable<
    SubscriptionResponse<OnCreateFirmsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateFirms($id: String, $zip: String, $name: String, $city: String, $state: String) {
        onCreateFirms(id: $id, zip: $zip, name: $name, city: $city, state: $state) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateFirmsSubscription>>;

  OnUpdateFirmsListener: Observable<
    SubscriptionResponse<OnUpdateFirmsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateFirms($id: String, $zip: String, $name: String, $city: String, $state: String) {
        onUpdateFirms(id: $id, zip: $zip, name: $name, city: $city, state: $state) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateFirmsSubscription>>;

  OnDeleteFirmsListener: Observable<
    SubscriptionResponse<OnDeleteFirmsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteFirms($id: String, $zip: String, $name: String, $city: String, $state: String) {
        onDeleteFirms(id: $id, zip: $zip, name: $name, city: $city, state: $state) {
          __typename
          id
          zip
          name
          city
          state
          street
          phone
          contact
          updated
          services
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteFirmsSubscription>>;
}
