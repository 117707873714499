<div class="landing">
    <div class="section cc-store-home-wrap">
        <div class="centered-container w-container">
            <h1 class="heading-19">Find Tax Help Near You.</h1>
            <p class="paragraph">Are you looking for a tax professional to help you with you taxes? Search the best tax
                professionals in your area.</p>
            <div class="div-1">
                <form class="search-2 w-form" [formGroup]="firmSearchForm" (ngSubmit)="onSubmit()"><input type="search"
                                                     class="search-input-2 w-input form-control"
                                                     autofocus="true" maxlength="256"
                                                     name="query"
                                                     formControlName="keywords"
                                                     placeholder="Zip code(ex. 10036)"
                                                     id="search"><input
                        type="submit" value="Search" class="search-button-2 w-button">
                    <img src="https://uploads-ssl.webflow.com/5e42ba1edad7dd756310249a/5e42ba1edad7ddc2f91024ee_search-24px.svg"
                         width="32" height="50" alt="" class="image-46">
                </form>
                <div class="up-right-div">
                    <div class="div-block-79"></div>
                    <div data-delay="0" class="profile-dropdown w-dropdown">
                        <div class="dropdown-toggle-4 w-dropdown-toggle">
                            <div class="div-block-78">
                                <img src="./assets/images/face-chad.jpeg" alt="" class="user-img">
                                <h4 class="heading-18">CPAs</h4>
                                <div class="icon-4 w-icon-dropdown-toggle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ul class="user-type-container">
                    <li class="user-type-list-item">
                        <a (click)="accountantSearch()" href="" class="profile-card">
                            <img class="gravatar-svg" src="./assets/images/client-dark.svg" alt="Polaris">
                            <div class="header">
                                <button mat-button color="accent">I'm looking for tax help.</button>
                            </div>
                        </a>
                    </li>
                    <li class="vertical-line"></li>
                    <li class="user-type-list-item">
                        <a (click)="accountantSearch()" href="" class="profile-card">
                            <img class="gravatar-svg" src="./assets/images/accountant-dark.svg" alt="Polaris">
                            <div class="header">
                                <button mat-button color="primary">I am an Accountant.</button>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
